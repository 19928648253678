<script>
    import AuthCard from '#login/shared/AuthCard.svelte';
    import StandardLogin from '#login/components/StandardLogin.svelte';
    import SSOLogin from '#login/components/SSOLogin.svelte';
    import { onlySSOLogin } from '#login/stores/settings.js';
</script>

<AuthCard>
    <div class="mt-14">
        {#if $onlySSOLogin}
            <SSOLogin />
        {:else}
            <StandardLogin />
        {/if}
    </div>
</AuthCard>
