import Login from '#login/pages/Login.svelte';
import ResetPassword from '#login/pages/ResetPassword.svelte';
import ForgotPassword from '#login/pages/ForgotPassword.svelte';

const routes = [
    {
        path: '/login',
        component: Login,
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
    },
    {
        path: '/password-reset/:token',
        component: ResetPassword,
    },
];

export { routes };
