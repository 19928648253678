<script>
    import Router from '#components/router/Router.svelte';
    import { routes } from '#login/routes.js';
    import { theme } from '#login/stores/theme.js';
    import { loginErrors } from '#login/stores/errors.js';
    import { translationsStore } from '#stores/translations/translations.js';
    import { enableSSO, onlySSOLogin } from '#login/stores/settings.js';

    export let config;
    export let themeOptions = {};
    export let errors = {};

    init();

    function init() {
        $translationsStore = config.translations;
        $theme = themeOptions ?? {};
        $loginErrors = errors ?? {};
        // eslint-disable-next-line no-undef
        $enableSSO = singleSignOnEnabled;
        // eslint-disable-next-line no-undef
        $onlySSOLogin = onlySSOEnabled;
    }
</script>

<Router {routes} />
