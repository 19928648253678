<script>
    import CapslockMessage from '#login/shared/CapslockMessage.svelte';
    import ShowPasswordButton from '#login/shared/ShowPasswordButton.svelte';

    export let id;
    export let name;
    export let password;
    export let isCapslockEnabled;
    export let errorMessage;

    let showPassword = false;

    function handleKeyDown(event) {
        const capsLockOn = event.getModifierState('CapsLock');
        isCapslockEnabled = capsLockOn;
    }

    function displayPassword() {
        showPassword = !showPassword;
    }
</script>

<input
    {id}
    {name}
    autocomplete="on"
    type={showPassword ? 'text' : 'password'}
    value={password ?? ''}
    required
    on:input={(event) => { password = event.target.value; }}
    on:keypress={handleKeyDown}
    class="w-full px-3 py-1 border rounded-md focus:outline-none {isCapslockEnabled ? 'focus:border-red-500 focus:ring-red-500' : ''}"
    class:border-red-500={errorMessage}
>

<CapslockMessage {isCapslockEnabled} />
<ShowPasswordButton id="show-{id}" on:click={displayPassword} {showPassword}/>
