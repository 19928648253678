<script>
    import AuthCard from '#login/shared/AuthCard.svelte';
    import FormFields from '#login/shared/FormFields.svelte';
    import Input from '#login/shared/Input.svelte';
    import Label from '#login/shared/Label.svelte';
    import PasswordInput from '#login/shared/PasswordInput.svelte';
    import SubmitButton from '#login/shared/SubmitButton.svelte';
    import { activeRouteContext, activeRouteQueryStringObject } from '#stores/routes/routes.js';
    import { AuthResource } from '#api/resources/AuthResource.js';
    import { translationsStore } from '#stores/translations/translations.js';

    const authResource = new AuthResource();

    const isCapslockEnabled = false;
    let email;
    let password;
    let confirmPassword;
    let token;
    let isRedirecting = false;
    let errorMessage = '';

    init();

    function init() {
        email = $activeRouteQueryStringObject.email ?? '';
        token = $activeRouteContext.params.token;
    }

    function resetPassword() {
        if (isRedirecting) {
            return;
        }

        isRedirecting = true;
        authResource
            .resetPassword(email, password, confirmPassword, token)
            .then((response) => {
                alert(response.data.status);
                window.location.href = '/';
            })
            .catch((error) => {
                isRedirecting = false;
                errorMessage = error.response.data.message;
            });
    }
</script>

<AuthCard>
    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
        <form on:submit|preventDefault={resetPassword}>
            <div class="space-y-4 mt-14 mb-8">
                <FormFields>
                    <Label name="email">{translationsStore.translate('login_email')}</Label>
                    <Input
                        id="email"
                        type="email"
                        name="email"
                        required
                        bind:value={email}
                        {errorMessage}
                    />
                </FormFields>

                <FormFields>
                    <Label name="password">{translationsStore.translate('profile_type_new_pwd')}</Label>
                    <div class="relative">
                        <PasswordInput
                            id="password"
                            name="password"
                            bind:password={password}
                            {isCapslockEnabled}
                            {errorMessage}
                        />
                    </div>
                </FormFields>

                <FormFields>
                    <Label name="password-confirmation">{translationsStore.translate('profile_retype_pwd')}</Label>
                    <div class="relative">
                        <PasswordInput
                            id="password-confirmation"
                            name="password-confirmation"
                            bind:password={confirmPassword}
                            {isCapslockEnabled}
                            {errorMessage}
                        />
                    </div>
                </FormFields>
            </div>

            <div class="relative">
                <SubmitButton id="standard-reset-button" {isRedirecting} on:click={resetPassword} {errorMessage}>
                    {translationsStore.translate('profile_pw_change')}
                </SubmitButton>
            </div>
        </form>
    </div>
</AuthCard>
