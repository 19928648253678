<script>
    import { fade } from 'svelte/transition';
    import { theme } from '#login/stores/theme.js';
    import { loginErrors } from '#login/stores/errors.js';
    import LogoBackgroundPlate from '#login/logos/logo-background-plate.svelte';
</script>

<div class="flex min-h-screen flex-col items-center justify-start bg-neutral-400 px-12">
    {#if $loginErrors.message}
        <div
            in:fade={{ duration: 150 }} class="absolute mt-4 w-full rounded-lg bg-red-600 px-4 py-2 text-center text-white sm:max-w-lg">
            <!-- eslint-disable-next-line svelte/no-at-html-tags -->
            {@html $loginErrors.message}
        </div>
    {/if}

    <div class="flex flex-row w-full justify-center">
        <div class="mt-40">
            <LogoBackgroundPlate />
        </div>

        <div class="mt-40 w-1/4 overflow-hidden rounded-r-lg bg-white shadow-xl sm:max-w-lg px-10">
            {#if $theme.customerLogo}
                <div class="flex justify-center">
                    <img class="mb-4 w-40" src={$theme.customerLogo} alt="Logo"/>
                </div>
            {/if}
            <slot />
        </div>
    </div>
</div>
